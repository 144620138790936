

























































import { defineComponent, ref, computed, watch, toRefs } from "@vue/composition-api";
import { DocumentAuditClient, DocumentsClient, DocumentSectionAuditEntry, SectionVersionDetails } from "@/api/OtiumAppApi";

export default defineComponent({
  name: "AuditTimelineDialogItem",
  props: {
    auditEntry: {
      type: DocumentSectionAuditEntry,
      required: true,
    },
  },
  setup(props) {
    const { auditEntry } = toRefs(props);

    const documentsClient = new DocumentsClient();
    const documentAuditClient = new DocumentAuditClient();

    const currentVersion = ref(null as SectionVersionDetails | null);
    const previousVersion = ref(null as SectionVersionDetails | null);
    const loadChanges = async () => {
      if (currentVersion.value != null)
        return; // don't load if we've already loaded it

      currentVersion.value = await documentsClient.getSectionVersionDetails(auditEntry.value.documentSectionVersionId);

      const sectionEntries = await documentAuditClient.getTimelineForSection(auditEntry.value.documentSectionId);
      const selectedIndex = sectionEntries.findIndex((x) => x.documentSectionVersionId === auditEntry.value.documentSectionVersionId);
      if (selectedIndex > 0)
        previousVersion.value = await documentsClient.getSectionVersionDetails(sectionEntries[selectedIndex - 1].documentSectionVersionId);
      else
        previousVersion.value = null;
    }

    const showDetails = async () => {
      await loadChanges();
      detailsVisible.value = true;
    }

    const hideDetails = () => {
      detailsVisible.value = false;
    }


    const hovered = ref(false);
    const detailsVisible = ref(false);

    return {
      hovered,
      detailsVisible,
      showDetails,
      hideDetails,
      currentVersion,
      previousVersion,
    };
  }
});
