

















































































import {
  CreateDocumentReferenceModel,
  DocumentReferenceModel,
  DocumentReferenceClient,
  UpdateDocumentReferenceDetailsModel
} from "@/api/OtiumAppApi";
import { useProjectStore } from "@/stores/projectStore";
import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  props: {
    openedDialogs: {
      type: Object,
      required: true
    },
    editingReference: {
      type: DocumentReferenceModel
    }
  },
  setup(props, context) {
    const projectStore = useProjectStore();
    const editReferenceDetails = ref("");
    watch(
      () => props.editingReference,
      (newValue) => {
        if (newValue) {
          editReferenceDetails.value = newValue.version.details;
        }
      }
    );

    const newReferenceDetails = ref("");
    const newReferenceName = ref("");

    const savingNewReference = ref(false);
    const saveNewReference = async () => {
      if (!projectStore.documentVersionIdInContext) return;
      savingNewReference.value = true;

      const client = new DocumentReferenceClient();
      await client.createReference(projectStore.documentVersionIdInContext, {
        name: newReferenceName.value,
        details: newReferenceDetails.value
      } as CreateDocumentReferenceModel);

      savingNewReference.value = false;
      props.openedDialogs.add = false;
      newReferenceDetails.value = "";
      newReferenceName.value = "";
    };

    const savingEditReference = ref(false);
    const saveEditReference = async () => {
      if (!props.editingReference) return;
      savingEditReference.value = true;
      const client = new DocumentReferenceClient();
      await client.updateVariable(props.editingReference.id, {
        details: editReferenceDetails.value
      } as UpdateDocumentReferenceDetailsModel);

      savingEditReference.value = false;
      props.openedDialogs.edit = false;
    };

    return {
      projectStore,
      editReferenceDetails,
      savingEditReference,
      saveEditReference,

      newReferenceName,
      newReferenceDetails,
      savingNewReference,
      saveNewReference
    };
  }
});
