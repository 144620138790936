































































































































































































































































































































































































































































































































































































































































import { ref, defineComponent, onMounted, onUnmounted } from "@vue/composition-api";
import { projectEditorHub } from "@/hubs/projectEditorHub";
import Editor from "../Editor/Editor.vue";
import AppBar from "../AppBar.vue";
import {
  DocumentsClient,
  RenameDocumentModel,
  DocumentHeadingModel,
  ExportClient,
  ProjectsClient,
  RenameProjectModel,
  DocumentAbbreviationModel,
  DocumentVariableModel,
  DocumentReferenceModel,
  DocumentVersionStatus,
  UpdateDocumentVersionStatusModel,
  UpdateDocumentVersionFontFamily,
  FontFamily
} from "@/api/OtiumAppApi";
import { DocumentPartType, DocumentTree, useProjectStore } from "@/stores/projectStore";
import DocumentSection from "./DocumentSection.vue";
import DocumentSectionEditor from "./DocumentSectionEditor.vue";
import CommentDialog from "./CommentDialog.vue";
import AbbreviationsList from "./AbbreviationsList.vue";
import VariablesList from "./VariablesList.vue";
import ReferencesList from "./ReferencesList.vue";
import AmendmentTimeline from "./AmendmentTimeline.vue";
import CreateDocumentDialog from "./CreateDocumentDialog.vue";
import OtiumBasePage from "@/components/OtiumBasePage.vue";
import Sidebar from "@/components/Editor/Sidebar.vue";
import { useUserStore } from "@/stores/userStore";
import { HelpSection } from "@/constants/helpSections";
import { useHelpDialogStore } from "@/stores/helpDialogStore";
import { useErrorStore } from "@/stores/errorStore";

export default defineComponent({
  components: {
    Editor,
    AppBar,
    OtiumBasePage,
    DocumentSection,
    DocumentSectionEditor,
    CommentDialog,
    VariablesList,
    AmendmentTimeline,
    CreateDocumentDialog,
    AbbreviationsList,
    ReferencesList,
    Sidebar
  },
  props: {
    projectId: {
      type: String,
      required: true
    }
  },
  setup(props, { root, refs }) {
    const projectStore = useProjectStore();
    const navDrawer = ref(null);
    const projectDocuments = ref([] as DocumentHeadingModel[]);
    const selectedElement = ref([]);
    const inputElements = ref([]);
    const userStore = useUserStore();

    const isLoadingProject = ref(false);
    onMounted(async () => {
      isLoadingProject.value = true;
      await projectEditorHub.start();
      await projectEditorHub.joinProject(props.projectId);
      await projectStore.loadProject(props.projectId);
      isLoadingProject.value = false;
    });

    onUnmounted(async () => {
      await projectEditorHub.stop();
      await projectStore.$reset();
    });

    const getSections = async (item: any) => {
      await projectStore.loadSections(item.id);
    };

    const displayAddDocument = ref(false);
    const addingDocument = ref(false);
    const addDocument = async (documentInfo: any) => {
      addingDocument.value = true;

      await projectStore.createDocument(documentInfo.name, documentInfo.templateId);

      addingDocument.value = false;
      displayAddDocument.value = false;
    };

    const sectionElements = ref<HTMLElement[]>([]);
    const selectedDocument = ref(undefined as DocumentHeadingModel | undefined);
    const isSelectingDocument = ref(false);
    const updateSelectedDocument = async (value: DocumentTree[]) => {
      if (value.length === 0) {
        projectStore.documentVersionIdInContext = undefined;
        return;
      }
      isSelectingDocument.value = true;
      const item = value[0];
      switch (item.type) {
        case DocumentPartType.DocumentVersion:
          await projectStore.selectDocument(item.id);
          break;
        case DocumentPartType.Section: {
          if (
            !projectStore.hasDocumentVersionInContext ||
            projectStore.documentVersionIdInContext !== item.documentVersionId
          ) {
            await projectStore.selectDocument(item.documentVersionId);
          }
          const sectionIndex = projectStore.flattenedSectionDetails.findIndex(
            (sectionDetail) => sectionDetail.id === item.id
          );
          if (sectionIndex === -1) return;
          const section = sectionElements.value[sectionIndex];
          if (section != undefined) section.scrollIntoView({ block: "center", behavior: "smooth" });
          break;
        }
      }
      isSelectingDocument.value = false;
    };

    const openDocument = ref([]);

    // Adding blank document
    const displayCreateBlankDocumentDialog = ref(false);

    const addBlankDocument = () => {
      displayCreateBlankDocumentDialog.value = true;
    };

    const iscreateBlankDocumentFormValid = ref(false);

    const newBlankDocumentName = ref("");

    // Adding a new section
    const displayAddSectionDialog = ref(false);
    const newSectionName = ref("");
    const newSectionParentSectionNumber = ref("");
    const newSectionOrder = ref(null as number | null);
    const newSectionParentSectionId = ref(null as string | null);
    const iscreateSectionFormValid = ref(false);
    const addingSection = ref(false);

    const addSection = async () => {
      addingSection.value = true;
      if (newSectionOrder.value !== null) {
        await projectStore.addSection(
          newSectionName.value,
          newSectionOrder.value,
          newSectionParentSectionId.value
        );
      }
      addingSection.value = false;
      displayAddSectionDialog.value = false;
      newSectionName.value = "";
      newSectionParentSectionNumber.value = "";
      newSectionOrder.value = null;
      newSectionParentSectionId.value = null;
    };

    const createSubsection = (sectionData: any) => {
      newSectionOrder.value = projectStore.getNextSectionOrder(sectionData.subsections);
      newSectionParentSectionNumber.value = sectionData.sectionNumber + ". " + sectionData.sectionTitle;
      newSectionParentSectionId.value = sectionData.sectionId;
      displayAddSectionDialog.value = true;
    };

    const addVersion = async (documentId: string) => {
      await projectStore.addDocumentVersion(documentId);
    };

    const addReviewVersion = async (documentId: string) => {
      await projectStore.addDocumentVersion(documentId, true);
    };

    const displayRenameDialog = ref(false);
    const newDocumentName = ref("");
    const renameDocumentId = ref(null as string | null);

    const openRenameDialog = (document: DocumentTree) => {
      renameDocumentId.value = document.id;
      newDocumentName.value = document.name;

      displayRenameDialog.value = true;
    };

    const savingDocumentName = ref(false);
    const saveDocumentName = async () => {
      if (!renameDocumentId.value) return;
      savingDocumentName.value = true;

      const client = new DocumentsClient();
      await client.renameDocument(renameDocumentId.value, {
        name: newDocumentName.value
      } as RenameDocumentModel);

      savingDocumentName.value = false;
      displayRenameDialog.value = false;
      renameDocumentId.value = null;
    };

    const displayRenameVersionDialog = ref(false);
    const newDocumentVersionName = ref("");

    const openRenameVersionDialog = () => {
      newDocumentVersionName.value = projectStore.documentVersionInContext?.versionName ?? "";

      displayRenameVersionDialog.value = true;
    };

    const savingDocumentVersionName = ref(false);
    const saveDocumentVersionName = async () => {
      if (!projectStore.documentVersionIdInContext) return;
      savingDocumentVersionName.value = true;

      const client = new DocumentsClient();
      await client.renameDocumentVersion(projectStore.documentVersionIdInContext, {
        name: newDocumentVersionName.value
      } as RenameDocumentModel);

      savingDocumentVersionName.value = false;
      displayRenameVersionDialog.value = false;
    };

    const exportingDocument = ref(false);
    const exportDocumentVersion = async (documentVersionId: string) => {
      try {
        exportingDocument.value = true;

        const client = new ExportClient();
        const response = await client.exportDocumentVersion(documentVersionId);

        if (!response?.data) return;

        const fileURL = window.URL.createObjectURL(response.data);
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Document Export.pdf");

        document.body.appendChild(fileLink);

        exportingDocument.value = false;
        fileLink.click();
      } catch {
        exportingDocument.value = false;
        const errorStore = useErrorStore();
        errorStore.addError("Error exporting document");
      }
    };

    const displayConfirmInReviewDialog = ref(false);
    const setReviewDocumentVersion = async (documentId: string, documentVersionId: string) => {
      const client = new DocumentsClient();
      await client.setDocumentVersionStatus(documentVersionId, {
        status: DocumentVersionStatus.Closed
      } as UpdateDocumentVersionStatusModel);
      await addReviewVersion(documentId);
      displayConfirmInReviewDialog.value = false;
    };

    const closeCreateNewDocumentVersion = async (documentId: string, documentVersionId: string) => {
      const client = new DocumentsClient();
      await client.setDocumentVersionStatus(documentVersionId, {
        status: DocumentVersionStatus.Closed
      } as UpdateDocumentVersionStatusModel);

      await addVersion(documentId);
    };

    const approveDocumentVersion = async (documentVersionId: string) => {
      const client = new DocumentsClient();
      await client.approveDocumentVersion(documentVersionId);
    };

    const displayRenameProjectDialog = ref(false);
    const newProjectName = ref("");
    const savingProjectName = ref(false);
    const saveProjectName = async () => {
      if (projectStore.projectId == undefined) return;
      savingProjectName.value = true;

      const client = new ProjectsClient();
      await client.updateProjectName(projectStore.projectId, {
        name: newProjectName.value
      } as RenameProjectModel);

      projectStore.projectName = newProjectName.value; //TODO: receive update from server
      displayRenameProjectDialog.value = false;
      savingProjectName.value = false;
    };

    const displayArchiveProjectDialog = ref(false);
    const archivingProject = ref(false);
    const archiveProject = async () => {
      if (projectStore.projectId == undefined) return;
      archivingProject.value = true;

      const client = new ProjectsClient();
      await client.archiveProject(projectStore.projectId);

      displayArchiveProjectDialog.value = false;
      archivingProject.value = false;

      root.$router.push({ name: "ArchivedProjects" });
    };

    const searchTerm = ref("");
    const showAmendmentDialog = ref(false);

    const openedAbbreviationDialogs = ref({ add: false, edit: true });
    const editingAbbreviation = ref<DocumentAbbreviationModel>();
    const onEditAbbreviationClick = (abbreviation: DocumentAbbreviationModel) => {
      openedAbbreviationDialogs.value.edit = true;
      editingAbbreviation.value = abbreviation;
    };

    const openedVariableDialogs = ref({ add: false, edit: false });
    const editingVariable = ref<DocumentVariableModel>();
    const onEditVariableClick = (variable: DocumentVariableModel) => {
      openedVariableDialogs.value.edit = true;
      editingVariable.value = variable;
    };

    const openedReferenceDialogs = ref({ add: false, edit: false });
    const editingReference = ref<DocumentReferenceModel>();
    const onEditReferenceClick = (reference: DocumentReferenceModel) => {
      openedReferenceDialogs.value.edit = true;
      editingReference.value = reference;
    };

    //adding comments
    const newCommentSection = ref(null as string | null);
    const displayAddCommentDialog = ref(false);

    const setFontFamily = async (fontFamily: FontFamily) => {
      if (!projectStore.documentVersionIdInContext) return;

      const fontSpaceStripped = fontFamily.replace(/ /g, "");
      const client = new DocumentsClient();
      client.updateDocumentVersionFontFamily(projectStore.documentVersionIdInContext, {
        fontFamily: fontSpaceStripped
      } as UpdateDocumentVersionFontFamily);
    };

    function openDocumentHelper() {
      const helpDialogStore = useHelpDialogStore();
      helpDialogStore.openDialog(HelpSection.Documents);
    }

    return {
      projectStore,
      userStore,
      inputElements,
      openDocument,
      selectedElement,
      iscreateBlankDocumentFormValid,
      newBlankDocumentName,
      displayAddDocument,
      projectDocuments,
      updateSelectedDocument,
      addBlankDocument,
      addingSection,
      addSection,
      getSections,
      selectedDocument,
      sectionElements,
      displayCreateBlankDocumentDialog,
      displayAddSectionDialog,
      newSectionName,
      newSectionOrder,
      newSectionParentSectionId,
      newSectionParentSectionNumber,
      iscreateSectionFormValid,
      addingDocument,
      addDocument,
      navDrawer,
      searchTerm,
      showAmendmentDialog,
      createSubsection,

      DocumentPartType,
      addVersion,
      displayRenameDialog,
      newDocumentName,
      renameDocumentId,
      openRenameDialog,
      savingDocumentName,
      saveDocumentName,

      displayArchiveProjectDialog,
      archivingProject,
      archiveProject,

      displayRenameVersionDialog,
      newDocumentVersionName,
      openRenameVersionDialog,
      savingDocumentVersionName,
      saveDocumentVersionName,

      exportingDocument,
      exportDocumentVersion,

      displayRenameProjectDialog,
      newProjectName,
      savingProjectName,
      saveProjectName,

      openedAbbreviationDialogs,
      editingAbbreviation,
      onEditAbbreviationClick,

      openedVariableDialogs,
      editingVariable,
      onEditVariableClick,

      openedReferenceDialogs,
      editingReference,
      onEditReferenceClick,

      DocumentVersionStatus,
      displayConfirmInReviewDialog,
      setReviewDocumentVersion,
      closeCreateNewDocumentVersion,
      approveDocumentVersion,
      newCommentSection,
      displayAddCommentDialog,

      setFontFamily,
      FontFamily,

      openDocumentHelper,
      isLoadingProject,
      isSelectingDocument
    };
  }
});
