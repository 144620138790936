


























































































// Vue
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
// Components
import EditorReadonly from "../Editor/EditorReadonly.vue";
import Editor from "../Editor/Editor.vue";
import htmlDiff from "node-htmldiff";

// Pinia
import { useProjectStore } from "@/stores/projectStore";
// API
import {
  DocumentSectionReviewModel,
  ReviewStatus,
  SectionDetails,
  SectionReviewModel,
  DocumentsClient
} from "@/api/OtiumAppApi";
// Dayjs
import dayjs from "dayjs";
import { useUserStore } from "@/stores/userStore";

interface ReviewOptions {
  status: ReviewStatus | null;
  icon: string | null;
  iconColor: string | null;
  text: string | null;
  value: string;
}

export default defineComponent({
  name: "SectionHistory",
  components: {
    EditorReadonly,
    Editor
  },
  props: {
    sectionId: {
      type: String as PropType<string>,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  emits: ["close"],
  setup(props, { emit }) {
    const projectStore = useProjectStore();

    function close() {
      emit("close");
    }

    // var diff = htmlDiff("<p>This is some text</p>", "<p>That is some more text</p>", "myClass");
    var diff = computed(() => {
      if (compareToContent.value != "") {
        let theDiff = htmlDiff(
          compareFromContent.value,
          compareToContent.value,
          "diffClass",
          null,
          "iframe,object,math,svg,script,video,head,style"
        );

        theDiff = theDiff.replaceAll(`class="diffClass"`, `class='diffClass'`);
        theDiff = theDiff.replaceAll(new RegExp(`data-operation-index=".{0,1}"`, "g"), "");
        theDiff = theDiff.replaceAll(`data-diff-node="ins"`, `data-diff-node='ins'`);
        return theDiff;
      }
      return null;
    });

    /**
     * Get the section details
     */
    const sectionDetails = computed((): SectionDetails | null => {
      var sectionDetails = projectStore.getSectionDetailsById(props.sectionId);
      if (sectionDetails != null) return sectionDetails;
      return null;
    });

    /**
     * The current content of the section
     */
    const sectionContent = computed((): string => {
      if (sectionDetails.value !== null) return sectionDetails.value.sectionVersionDetails.content;
      return "";
    });

    // Get the model of the section review
    const sectionReviewModel = computed((): DocumentSectionReviewModel | null => {
      return sectionDetails.value?.sectionReview ?? null;
    });

    // List of all reviews
    const allReviews = computed((): SectionReviewModel[] => {
      if (sectionReviewModel.value != null) {
        return sectionReviewModel.value.reviews;
      }

      return [];
    });

    /**
     * Maps the review status to an icon.
     * The icons will be inserted into a <v-icon> element
     */
    function mapStatusToIcon(status: ReviewStatus): string | null {
      switch (status) {
        case ReviewStatus.Open:
          return null;
        case ReviewStatus.Accepted:
          return "mdi-check";
        case ReviewStatus.AcceptedWithEdits:
          return "mdi-check";
        case ReviewStatus.Rejected:
          return "mdi-close";
      }
    }

    /**
     * Maps the status to a color
     */
    function mapStatusToColor(status: ReviewStatus): string | null {
      switch (status) {
        case ReviewStatus.Open:
          return null;
        case ReviewStatus.Accepted:
          return "primary";
        case ReviewStatus.AcceptedWithEdits:
          return "primary";
        case ReviewStatus.Rejected:
          return "error";
      }
    }

    // List of all reviews
    const allReviewOptions = computed((): any[] => {
      const reviews = allReviews.value.map((r, i) => {
        const option: ReviewOptions = {
          status: r.status,
          icon: mapStatusToIcon(r.status),
          iconColor: mapStatusToColor(r.status),
          text: `Review ${allReviews.value.length - i}: ${r.reviewerName} ${dayjs(r.createdOn).format(
            "YYYY-MM-DD hh:mm:ssA"
          )}`,
          value: r.documentSectionReviewId
        };
        return option;
      });
      const defaultOption: ReviewOptions = {
        status: null,
        icon: null,
        iconColor: null,
        text: "Current Version",
        value: "0"
      };
      const originalOption: ReviewOptions = {
        status: null,
        icon: null,
        iconColor: null,
        text: "Original Version",
        value: "-1"
      };

      reviews.unshift(originalOption);
      reviews.unshift(defaultOption);
      return reviews;
    });

    //#endregion

    /**
     * Original Content
     */
    const originalContent = ref<string>("");
    async function loadOriginal() {
      const documentsClient = new DocumentsClient();
      const response = await documentsClient.getSectionVersionOriginalDetails(props.sectionId);
      originalContent.value = response.content;
    }
    loadOriginal();

    /**
     * compareFrom
     */
    const compareFromId = ref<string | null>("0");
    const compareFromContent = computed(() => {
      return loadReviewContentById(compareFromId.value ?? "0");
    });

    /**
     * Compare to
     */
    const compareToOption = ref<ReviewOptions | null>(null);
    const compareToContent = computed(() => {
      return loadReviewContentById(compareToOption.value?.value ?? "-99");
    });

    /**
     * Load review
     */
    function loadReviewContentById(id: string): string {
      if (id == "0") return sectionContent.value;
      if (id == "-1") return originalContent.value;
      const review = allReviews.value.filter((r) => r.documentSectionReviewId == id);
      if (review != null && review.length > 0) return review[0].content;
      else return "";
    }

    //#endregion

    /**
     * Reject a review
     */
    async function reject() {
      if (compareToOption.value != null)
        await projectStore.rejectReviewAsync(compareToOption.value.value);
      compareToOption.value = null;
    }

    /**
     * Edit a review before accepting
     */
    const isEditing = ref<boolean>(false);
    const editingContent = ref<string | null>(null);
    async function edit() {
      if (compareToOption.value != null) {
        isEditing.value = true;
        editingContent.value = diff.value;
      }
    }

    /**
     * Takes the content that was edited and passes that as the review
     */
    async function acceptEdit() {
      if (compareToOption.value != null && editingContent.value != null) {
        // Remove the insert tags to remove the styling
        editingContent.value = editingContent.value.replaceAll(
          `<ins style="background-color: lightgreen; text-decoration: none">`,
          ""
        );

        // Remove any ins tags that may be present. This would usually be inside a custom component
        // For some reason some of the ins tags have an extra space between the open tag and the class so need to remove both
        editingContent.value = editingContent.value.replaceAll(`<ins  class='diffClass'>`, "");
        editingContent.value = editingContent.value.replaceAll(`<ins class='diffClass'>`, "");

        editingContent.value = editingContent.value.replaceAll("</ins>", "");

        // Remove all <s> and <del> tag pairs
        editingContent.value = editingContent.value.replaceAll(new RegExp("<s>.*</s>", "g"), "");
        editingContent.value = editingContent.value.replaceAll(new RegExp("<del.*</del>", "g"), "");

        await projectStore.acceptReviewWithChangesAsync(
          compareToOption.value.value,
          editingContent.value
        );
        isEditing.value = false;
        editingContent.value = null;
        compareToOption.value = null;
      }
    }

    /**
     * Cancels the edit process of the review, returning it back to the compare changes view
     */
    async function cancelEdit() {
      isEditing.value = false;
      editingContent.value = null;
    }

    /**
     * Determine whether to display the actions.
     * Hide the actions if the "Original" selection is not the "Current Version"
     * Hide the actions if the "Compare To" version does not have an "Open" status
     */
    const disableActions = computed(() => {
      if (!props.canEdit) return true;
      if (compareToOption && compareToOption.value?.status != ReviewStatus.Open) return true;
      if (compareFromId.value != "0") return true;
      return false;
    });

    const isWriter = computed(() => {
      const userStore = useUserStore();
      return userStore.isWriter(projectStore.projectId ?? "");
    });

    return {
      projectStore,

      close,

      allReviews,
      allReviewOptions,

      compareFromContent,
      compareFromId,
      compareToOption,
      compareToContent,
      diff,

      reject,
      edit,
      disableActions,
      isEditing,
      editingContent,
      acceptEdit,
      cancelEdit,
      isWriter
    };
  }
});
