
























































import Vue from "vue";
import { ref, defineComponent, computed, watch, toRefs } from "@vue/composition-api";
import { AccountModel, DocumentAuditClient, DocumentSectionAuditEntry } from "@/api/OtiumAppApi";
import AmendmentTimelineItem from "./AmendmentTimelineItem.vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "AmendmentTimeline",
  components: { AmendmentTimelineItem },
  props: {
    documentVersionId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { documentVersionId } = toRefs(props);
    const client = new DocumentAuditClient();
    const auditEntries = ref<DocumentSectionAuditEntry[]>([]);

    const loadTimeline = async () => {
      const entries = await client.getTimelineForDocumentVersion(props.documentVersionId);
      sections.value = {};
      auditEntries.value = entries;
      entries.forEach((auditEntry) => {
        if (sections.value[auditEntry.documentSectionId] == undefined)
          Vue.set(sections.value, auditEntry.documentSectionId, auditEntry.sectionTitle);
        if (authors.value[auditEntry.account.accountId] == undefined)
          Vue.set(authors.value, auditEntry.account.accountId, auditEntry.account);
      });
    };
    watch(documentVersionId, loadTimeline);
    loadTimeline();

    enum SortField {
      DateNewest,
      DateOldest
    }
    const sortBy = ref(SortField.DateNewest);
    const filterSection = ref(null as string | null);
    const filterAuthor = ref(null as string | null);

    const filteredAuditEntries = computed(() =>
      auditEntries.value
        .filter(
          (entry) =>
            (filterSection.value == null || entry.documentSectionId == filterSection.value) &&
            (filterAuthor.value == null || entry.account.accountId == filterAuthor.value)
        )
        .sort((a, b) => {
          switch (sortBy.value) {
            case SortField.DateNewest:
              return dayjs(b.createdOn).unix() - dayjs(a.createdOn).unix();
            case SortField.DateOldest:
              return dayjs(a.createdOn).unix() - dayjs(b.createdOn).unix();
          }
        })
    );

    const sections = ref({} as { [key: string]: string });

    const sortedSections = computed(() => {
      const sectionMap = sections.value;
      const sortedSectionsA = [] as { id: string; name: string }[];
      Object.keys(sectionMap).forEach((key) => {
        sortedSectionsA.push({ id: key, name: sectionMap[key] });
      });
      return sortedSectionsA.sort((a, b) => a.name.localeCompare(b.name));
    });

    const authors = ref({} as { [key: string]: AccountModel });
    const sortedAuthors = computed(() => {
      const authorMap = authors.value;
      const sortedAuthors = [] as AccountModel[];
      Object.keys(authorMap).forEach((key) => {
        sortedAuthors.push(authorMap[key]);
      });
      return sortedAuthors.sort((a, b) =>
        `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
      );
    });

    return {
      SortField,
      sortBy,
      filterSection,
      filterAuthor,
      filteredAuditEntries,
      sortedSections,
      sortedAuthors
    };
  }
});
