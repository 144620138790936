













































































import { DocumentStatus, DocumentVersionStatus, SectionDetails } from "@/api/OtiumAppApi";
import { useProjectStore } from "@/stores/projectStore";
import { useUserStore } from "@/stores/userStore";
import { computed, defineComponent, ref } from "@vue/composition-api";
import EditorReadonly from "../Editor/EditorReadonly.vue";

import SectionReview from "./SectionReview.vue";

export default defineComponent({
  props: {
    section: {
      type: SectionDetails,
      required: true
    }
  },
  components: {
    SectionReview,
    EditorReadonly
  },
  setup(props, context) {
    const projectStore = useProjectStore();
    const userStore = useUserStore();
    const canEdit = computed(() => {
      const currentStatus = projectStore.documentVersionInContext?.status;
      return (
        (currentStatus == DocumentVersionStatus.InReview && isReviewer.value) ||
        (currentStatus == DocumentVersionStatus.Draft &&
          userStore.isWriter(projectStore.projectId ?? ""))
      );
    });
    const canEditReviews = computed(() => {
      const currentStatus = projectStore.documentVersionInContext?.status;
      return (
        currentStatus == DocumentVersionStatus.InReview &&
        userStore.isWriter(projectStore.projectId ?? "")
      );
    });
    const edit = () => {
      if (canEdit.value) {
        context.emit("edit");
      }
    };
    const editCardClicked = () => {
      if (reviewSectionId.value == null) {
        edit();
      }
    };

    function createSubsection() {
      context.emit("createSubsection", {
        sectionId: props.section.id,
        subsections: props.section.subsections,
        sectionNumber: sectionNumber.value,
        sectionTitle: props.section.sectionVersionDetails.title
      });
    }
    const sectionNumber = computed(() => props.section.sectionNumber.map((n) => n + 1).join("."));
    const headerTag = computed(() => {
      const depth = props.section.sectionNumber.length;
      if (depth > 6) return "h6";
      else if (depth < 1) return "h1";
      else return "h" + depth;
    });
    const addComment = () => {
      context.emit("comment");
    };

    // Reviews
    const reviewSectionId = ref<string | null>(null);
    function showReviews() {
      reviewSectionId.value = props.section.id;
    }

    // Determine if the document section is being updated by a reviewer
    const isReviewer = computed(() => {
      return !userStore.isWriter(projectStore.projectId ?? "");
    });

    return {
      edit,
      createSubsection,
      projectStore,
      sectionNumber,
      headerTag,
      addComment,
      showReviews,
      isReviewer,
      reviewSectionId,
      canEdit,
      canEditReviews,
      editCardClicked
    };
  }
});
