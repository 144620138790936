






































































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import {
  DocumentSectionCommentClient,
  DocumentVersionStatus,
  CreateCommentModel,
  DocumentSectionCommentModel,
  CommentStatus
} from "@/api/OtiumAppApi";
import { useProjectStore } from "@/stores/projectStore";
import { useUserStore } from "@/stores/userStore";

export default defineComponent({
  name: "CommentDialog",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    sectionId: {
      type: String,
      required: true
    }
  },
  emits: ["close"],
  setup(props, { emit, refs }) {
    const newComment = ref("");
    const projectStore = useProjectStore();
    const userStore = useUserStore();
    const requiredRule = (v: string | null) => {
      return v != null && v.trim() != "" ? true : "Required";
    };
    const isCreateCommentFormValid = ref(false);
    const tab = ref<number | undefined>(undefined);
    const isMessageSending = ref(false);

    const save = async () => {
      const form = (refs["createCommentForm"] as HTMLFormElement[])[0];
      form.validate();
      if (!isCreateCommentFormValid.value) return;
      const client = new DocumentSectionCommentClient();
      isMessageSending.value = true;
      try {
        await client.createComment(props.sectionId, {
          documentSectionId: props.sectionId,
          comment: newComment.value
        } as CreateCommentModel);
        form.reset();
      } finally {
        isMessageSending.value = false;
      }
    };

    const resolveComment = async (documentSectionCommentId: string) => {
      const client = new DocumentSectionCommentClient();
      await client.resolve(documentSectionCommentId);
    };

    function close() {
      emit("close");
    }

    const comments = computed(() => {
      const section = projectStore.flattenedSectionDetails.find((x) => x.id === props.sectionId);
      if (!section) return undefined;
      const sectionComments = section?.comments;
      const versionName = projectStore.documentVersionInContext?.versionName;
      if (!versionName) return sectionComments;

      // Add current draft if it doesn't exist
      if (
        !sectionComments.some(
          (x: DocumentSectionCommentModel) => x.originatingDocumentVersionName === versionName
        )
      ) {
        sectionComments.unshift(
          new DocumentSectionCommentModel({
            openCommentCount: 0,
            documentSectionId: section.id,
            originatingDocumentVersionName: versionName,
            comments: []
          })
        );
      }

      return sectionComments;
    });

    return {
      close,
      save,
      tab,
      comments,
      newComment,
      requiredRule,
      isCreateCommentFormValid,
      isMessageSending,
      projectStore,
      userStore,
      DocumentVersionStatus,
      CommentStatus,
      resolveComment
    };
  },
  components: {}
});
