

































































































import { ref, defineComponent, onMounted, computed, watch } from "@vue/composition-api";
import { TagsClient, TemplatesClient } from "@/api/OtiumAppApi";

export class NewDocumentInfo {
  name = "";
  type: string | null = null;
  jurisdiction: string | null = null;
  phase: string | null = null;
  intervention: string | null = null;
  template: string | null = null;
}

export default defineComponent({
  name: "CreateDocumentDialog",
  components: {},
  emits: ["create", "cancel"],
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root, refs, emit }) {
    const tagsClient = new TagsClient();
    const templatesClient = new TemplatesClient();

    const documentName = ref("");

    const types = ref<any[]>([]);
    const jurisdictions = ref<any[]>([]);
    const phases = ref<any[]>([]);
    const interventions = ref<any[]>([]);

    const selectedType = ref<string | null>(null);
    const selectedJurisdiction = ref<string | null>(null);
    const selectedPhase = ref<string | null>(null);
    const selectedIntervention = ref<string | null>(null);

    const getCategories = async () => {
      const categories = await tagsClient.getCategories();
      categories.forEach((category) => {
        const selectItems = category.tags.map((x) => ({ text: x.name, value: x.tagId }));
        switch (category.name) {
          case "Document Types":
            types.value = selectItems;
            break;
          case "Jurisdiction":
            jurisdictions.value = selectItems;
            break;
          case "Phase":
            phases.value = selectItems;
            break;
          case "Interventions":
            interventions.value = selectItems;
            break;
        }
      });
    };
    onMounted(getCategories);

    const templates = ref<any[]>([]);
    const selectedTemplate = ref<string | null>(null);
    const loadingTemplates = ref(false);
    async function getTemplates() {
      selectedTemplate.value = null;
      templates.value = [];
      if (
        selectedType.value != null &&
        selectedJurisdiction.value != null &&
        selectedPhase.value != null &&
        selectedIntervention.value != null
      ) {
        const tagIds = [
          selectedType.value,
          selectedJurisdiction.value,
          selectedPhase.value,
          selectedIntervention.value
        ];
        loadingTemplates.value = true;
        const templateSummaries = await templatesClient.getTemplatesForTags(tagIds);
        templates.value = templateSummaries.map((template) => ({
          text: template.name,
          value: template.id
        }));
        loadingTemplates.value = false;
      }
    }
    watch([selectedType, selectedJurisdiction, selectedPhase, selectedIntervention], getTemplates);

    const requiredRule = (v: string | null) => (v != null && v.trim() != "" ? true : "Required");
    const isCreateDocumentFormValid = ref(false);
    function validateForm() {
      (refs.createDocumentForm as HTMLFormElement).validate();
      return isCreateDocumentFormValid.value;
    }

    function create() {
      if (validateForm()) {
        emit("create", { name: documentName.value, templateId: selectedTemplate.value });
        (refs.createDocumentForm as HTMLFormElement).reset();
      }
    }

    function cancel() {
      (refs.createDocumentForm as HTMLFormElement).reset();
      emit("cancel");
    }

    return {
      types,
      jurisdictions,
      phases,
      interventions,
      templates,

      documentName,
      selectedType,
      selectedJurisdiction,
      selectedPhase,
      selectedIntervention,
      selectedTemplate,

      loadingTemplates,

      requiredRule,
      isCreateDocumentFormValid,

      create,
      cancel
    };
  }
});
