















































































































































































import { DocumentStatus, SectionDetails } from "@/api/OtiumAppApi";
import { useProjectStore } from "@/stores/projectStore";
import { useUserStore } from "@/stores/userStore";
import { computed, defineComponent, ref } from "@vue/composition-api";
import Editor from "../Editor/Editor.vue";

export default defineComponent({
  props: {
    sectionId: {
      type: String,
      required: true
    }
  },
  setup(props, { refs }) {
    const projectStore = useProjectStore();
    const name = ref(projectStore.sectionDetailsInContext?.sectionVersionDetails.title ?? "");
    const contents = ref(projectStore.sectionDetailsInContext?.sectionVersionDetails.content ?? "");

    const confirmDiscard = ref(false);
    const confirmDelete = ref(false);

    const confirmSave = ref(false);

    const reasons = ref<any[]>([]);
    const loadReasons = async () => {
      if (reasons.value.length > 0) return;
      const selectItems = projectStore.saveReasons.map((x) => {
        return {
          text: x.value,
          value: x.id
        };
      });
      reasons.value.push({ text: "", value: null });
      reasons.value.push(...selectItems);
    };

    const selectedReason = ref<string | null>(null);
    const comment = ref("");

    const saving = ref(false);
    const save = async (validate = true) => {
      if (validate) {
        (refs.confirmSaveForm as any).validate();
        if (!isConfirmSaveFormValid.value) return;
      }

      saving.value = true;
      await projectStore.updateSection(
        props.sectionId,
        name.value,
        contents.value,
        comment.value,
        selectedReason.value
      );

      confirmSave.value = false;
      comment.value = "";
      saving.value = false;
    };

    /**
     * Creates a new review for the section
     */
    const addReview = async () => {
      await projectStore.addReview(props.sectionId, contents.value);

      confirmSave.value = false;
      comment.value = "";
      saving.value = false;
    };

    const discard = () => {
      projectStore.sectionIdInContext = undefined;

      confirmDiscard.value = false;
    };

    const deleteSection = async () => {
      await projectStore.deleteSection(props.sectionId);

      confirmDelete.value = false;
    };

    const showMoveDialog = ref(false);
    const newSectionOrder = ref(null as number | null);
    const sectionsForReorder = computed(() => {
      let treeIndex = projectStore.sectionDetailsInContext?.sectionNumber;
      if (treeIndex == null) return [];
      treeIndex = [...treeIndex];
      treeIndex.pop(); // we want to get the parent of the section
      let sections: SectionDetails[];
      if (treeIndex.length > 0) {
        sections = projectStore.getSectionFromSectionNumber(
          projectStore.sectionDetailsTree,
          treeIndex
        ).subsections;
      } else {
        sections = projectStore.sectionDetailsTree;
      }
      const sectionOptions = sections.map((s) => ({
        value: s.order,
        text: s.sectionVersionDetails.title
      }));
      const lastSection =
        sectionOptions.length > 0 ? sectionOptions[sectionOptions.length - 1].value : 0;
      sectionOptions.push({ value: lastSection + 1, text: "< move to end >" });
      return sectionOptions;
    });
    const reorderingSection = ref(false);
    const reorderSection = async () => {
      if (projectStore.sectionIdInContext && newSectionOrder.value !== null) {
        reorderingSection.value = true;
        await projectStore.updateTemplateOrder(projectStore.sectionIdInContext, newSectionOrder.value);
      }
      reorderingSection.value = false;
      showMoveDialog.value = false;
    };

    // Determine if the document section is being updated by a reviewer
    const isReviewer = computed(() => {
      const userStore = useUserStore();
      return !userStore.isWriter(projectStore.projectId ?? "");
    });

    const saveChangesClicked = async () => {
      if (!projectStore.documentInContext) return;

      if (isReviewer.value) {
        await addReview();
      } else if (projectStore.documentInContext.status === DocumentStatus.Final) {
        await loadReasons();
        confirmSave.value = true;
      } else {
        await save(false);
      }
    };

    /** Validations */
    const requiredRule = ref((v: string | null) => (v != null && v.trim() != "" ? true : "Required"));
    const isConfirmSaveFormValid = ref(false);

    return {
      name,
      requiredRule,
      isConfirmSaveFormValid,
      contents,
      reasons,
      selectedReason,
      comment,
      confirmDiscard,
      confirmDelete,
      confirmSave,
      showMoveDialog,
      newSectionOrder,
      sectionsForReorder,
      reorderingSection,
      reorderSection,
      saving,
      save,
      discard,
      deleteSection,
      saveChangesClicked,
      projectStore,
      isReviewer
    };
  },
  components: { Editor }
});
