





































import { defineComponent } from "@vue/composition-api";
import { useUserStore } from "@/stores/userStore";

export default defineComponent({
  setup(props, { root }) {
    const userStore = useUserStore();

    function logOut() {
      // Clear access token
      userStore.logout();

      // Redirect to login page
      root.$router.push({ name: "Home" });
    }

    return {
      logOut
    };
  }
});
