import _Vue from "vue";
import * as signalR from "@microsoft/signalr";
import { useProjectStore } from "@/stores/projectStore";
import {
  DocumentHeadingModel,
  DocumentReferenceModel,
  DocumentAbbreviationModel,
  DocumentVersionHeadingModel,
  DocumentVariableModel,
  SectionHeading,
  SectionDetails,
  SectionCommentModel,
  SectionVersionDetails,
  SectionVersionHeading,
  DocumentSectionCommentModel,
  FontFamily,
  SectionReviewModel,
  DocumentSectionReviewModel,
  ReviewStatus
} from "@/api/OtiumAppApi";
import { useUserStore } from "@/stores/userStore";

const connection = new signalR.HubConnectionBuilder()
  .configureLogging(signalR.LogLevel.Information)
  .withUrl(`${process.env.VUE_APP_API_BASE_URL}/projectEditorHub`, {
    accessTokenFactory: () => {
      const userStore = useUserStore();
      return userStore.accessToken ?? "";
    }
  })
  .withAutomaticReconnect({
    nextRetryDelayInMilliseconds: (retryContext) => {
      const reconnectDelay = Math.random() * 5000 + 2000;
      return reconnectDelay;
    }
  })
  .build();

connection.onreconnecting(function () {
  const projectStore = useProjectStore();
  projectStore.isHubConnected = false;
});

connection.onreconnected(function () {
  const projectStore = useProjectStore();
  projectStore.isHubConnected = true;

  // SignalR groups are lost on disconnect.
  // Rejoin the project.
  if (projectStore.projectId !== undefined) {
    projectEditorHub.joinProject(projectStore.projectId);
  }
});

/**
 * A new section comment has been added to a document.
 */
connection.on("DocumentSectionCommentAdded", (projectId: string, model: any) => {
  const projectStore = useProjectStore();
  const comment = SectionCommentModel.fromJS(model);
  projectStore.receiveNewComment(comment);
});

/**
 * A comment has been resolved.
 */
connection.on("DocumentSectionCommentResolved", (projectId: string, model: any) => {
  const projectStore = useProjectStore();
  const comment = SectionCommentModel.fromJS(model);
  projectStore.receiveCommentResolved(comment);
});

/**
 * A review status has been updated.
 */
connection.on("DocumentSectionReviewStatusUpdated", (projectId: string, model: any) => {
  const projectStore = useProjectStore();
  const review = SectionReviewModel.fromJS(model);
  projectStore.receiveReviewStatusUpdated(review);
});

/**
 * A new section review has been added to a document.
 */
connection.on("DocumentSectionReviewAdded", (projectId: string, model: any) => {
  const projectStore = useProjectStore();
  const review = SectionReviewModel.fromJS(model);
  projectStore.receiveNewReview(review);
});

/**
 * A section within a document has been updated.
 */
connection.on("DocumentSectionVersionCreated", async (projectId: string, model: any) => {
  const projectStore = useProjectStore();

  const sectionDetails = SectionDetails.fromJS(model);
  await projectStore.receiveNewSectionVersion(sectionDetails);
});

/**
 * A new section has been added to a document.
 */
connection.on("DocumentSectionAdded", (projectId: string, model: any) => {
  const projectStore = useProjectStore();

  const details = SectionDetails.fromJS(model);
  projectStore.receiveNewSection(details);
});

/**
 * A section in a document version has been deleted.
 */
connection.on(
  "DocumentSectionDeleted",
  async (projectId: string, documentVersionId: string, documentSectionId: string) => {
    const projectStore = useProjectStore();

    await projectStore.removeSection(documentVersionId, documentSectionId);
  }
);

/**
 * A new section has been added to a document.
 */
connection.on("DocumentVersionCreated", (projectId: string, model: any) => {
  const projectStore = useProjectStore();

  const heading = DocumentVersionHeadingModel.fromJS(model);

  projectStore.receiveNewDocumentVersion(heading);
});

/**
 * A new document has been added to a project.
 */
connection.on("DocumentCreated", (projectId: string, model: any) => {
  const projectStore = useProjectStore();

  const document = DocumentHeadingModel.fromJS(model);
  projectStore.receiveNewDocument(document);
});

/**
 * A new document has been renamed.
 */
connection.on("DocumentRenamed", (projectId: string, documentId: string, name: string) => {
  const projectStore = useProjectStore();

  projectStore.updateDocumentName(documentId, name);
});

/**
 * A new document has been renamed.
 */
connection.on("DocumentVersionRenamed", (projectId: string, documentVersionId: string, name: string) => {
  const projectStore = useProjectStore();

  projectStore.updateDocumentVersionName(documentVersionId, name);
});

/**
 * A new document has been renamed.
 */
connection.on(
  "DocumentVersionStatusUpdated",
  (projectId: string, documentVersionId: string, status: number) => {
    const projectStore = useProjectStore();

    projectStore.updateDocumentVersionStatus(documentVersionId, status);
  }
);

/**
 * A new section has been added to a document.
 */
connection.on("SectionsReordered", async (projectId: string, model: any) => {
  const projectStore = useProjectStore();
  const sectionDetails = SectionDetails.fromJS(model);
  await projectStore.refreshOrdering(sectionDetails);
});

/**
 * A new abbreviation has been added to a document.
 */
connection.on(
  "DocumentAbbreviationCreated",
  async (projectId: string, model: DocumentAbbreviationModel) => {
    const projectStore = useProjectStore();
    projectStore.receiveNewAbbreviation(model);
  }
);

/**
 * A abbreviation has been updated.
 */
connection.on(
  "DocumentAbbreviationUpdated",
  async (projectId: string, model: DocumentAbbreviationModel) => {
    const projectStore = useProjectStore();
    projectStore.updateAbbreviation(model);
  }
);

/**
 * A new reference has been added to a document.
 */
connection.on("DocumentReferenceCreated", async (projectId: string, model: DocumentReferenceModel) => {
  const projectStore = useProjectStore();
  projectStore.receiveNewReference(model);
});

/**
 * A reference has been updated.
 */
connection.on("DocumentReferenceUpdated", async (projectId: string, model: DocumentReferenceModel) => {
  const projectStore = useProjectStore();
  projectStore.updateReference(model);
});

/**
 * A new variable has been added to a document.
 */
connection.on("DocumentVariableCreated", async (projectId: string, model: DocumentVariableModel) => {
  const projectStore = useProjectStore();
  projectStore.receiveNewVariable(model);
});

/**
 * A variable has been updated.
 */
connection.on("DocumentVariableUpdated", async (projectId: string, model: DocumentVariableModel) => {
  const projectStore = useProjectStore();
  projectStore.updateVariable(model);
});

/**
 * A font has been updated.
 */
connection.on(
  "DocumentVersionFontUpdated",
  (projectId: string, documentVersionId: string, fontFamily: FontFamily) => {
    const projectStore = useProjectStore();
    projectStore.updateDocumentVersionFont(documentVersionId, fontFamily);
  }
);

export interface ProjectEditorHub {
  connection: signalR.HubConnection;
  start(): Promise<void>;
  stop(): Promise<void>;
  joinProject(projectId: string): Promise<void>;
}

export const projectEditorHub = {
  connection: connection,

  async start() {
    try {
      const projectStore = useProjectStore();

      if (projectStore.isHubConnected) return;

      await connection.start();

      projectStore.isHubConnected = true;
    } catch (err) {
      console.error(err);
      setTimeout(this.start, 5000);
    }
  },

  async stop() {
    await connection.stop();
  },

  async joinProject(projectId: string) {
    await connection.send("ConnectToProject", projectId);
  }
} as ProjectEditorHub;

export function ProjectEditorHub(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$projectEditorHub = projectEditorHub;
}
